import { Modal } from "antd";
import styled from "styled-components";

export const CustomStyledModal = styled(Modal)`
  .ant-modal-body {
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    overflow: hidden;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
      env(safe-area-inset-left);
  }

  .ant-modal-content {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
      env(safe-area-inset-left);
    background: #272727;
    border-radius: 0;
    max-width: 350px;
    position: fixed;
    right: 0 !important;
    top: 0 !important;
    width: 100%;
    box-sizing: border-box;
  }

  .ant-modal-mask {
    transition: none !important;
  }

  .ant-menu-light .ant-menu-item {
    @media screen and (max-width: 1024px) {
      color: white;
    }
  }

  .ant-menu-light .ant-menu-item-selected {
    color: #81d6e8;
  }

  .ant-modal-close {
    font-size: 20px;
    color: white;
  }

  .ant-modal-root {
    max-width: 100%;
    overflow: hidden;
    margin: 0;

    @media screen and (max-width: 1024px) {
      margin: 0;
      width: 100%;
    }
  }

  .ant-modal-root .ant-modal {
    @media screen and (max-width: 1024px) {
      margin: 0 !important;
      width: 100vw;
    }
  }
  .any-modal {
    top: 0 !important;
  }
`;
